// @ts-nocheck
import "../styles/globals.css";
import { Analytics } from "@vercel/analytics/react";
import { HydrationProvider, Server, Client } from "react-hydration-provider";

import { Toaster } from "react-hot-toast";
import { SessionProvider as AuthProvider } from "next-auth/react";
import Router from "next/router";
import NProgress from "nprogress";
import "../styles/NProgress.css";
import { useEffect } from "react";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  return (
    <HydrationProvider>
      <AuthProvider session={session}>
        <Client>
          <Component {...pageProps} />
          <Toaster />
        </Client>
      </AuthProvider>
    </HydrationProvider>
  );
}

export default MyApp;
